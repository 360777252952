import React from "react";
import { useTranslation } from "react-i18next";

export const Conferences = (props) => {
  const { t } = useTranslation();
  return (
    <div id="conferences">
      <div className="container">
        <div className="section-title text-center">
          <h2> <span style={{ color: "rgba(107, 199, 183, 1)" }}>Hadal</span> {t("Conferences.conferencesTitle")}</h2>
        </div>

        <div className="row" style={
          {
            marginTop: "5vh",
            marginLeft: "0px",
            display: "flex",
            justifyContent: "center",  // гарантирует центрирование горизонтально
            height: "auto",  // убираем фиксированную высоту
            width: "100%",   // адаптируется к размеру экрана

          }
        }>
          <div
            style={{
              position: "relative",
              width: "70%",  // ширина видео по сравнению с контейнером
              paddingBottom: "39.375%",  // отношение 16:9 (9/16*100%)
              height: 0,
              overflow: "hidden",
            }}
          >


            <iframe
              // width="720"
              // height="405"
              src="https://runtime.strm.yandex.ru/player/video/vplvuvcwtz43k5xa3az6?autoplay=0&mute=1"
              frameBorder="0"
              allow="clipboard-write; autoplay"
              webkitAllowFullScreen
              mozallowfullscreen
              allowFullScreen
              style={
                {
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }
              }
            >
            </iframe>
          </div>
        </div>
        <div className="row" style={
          {
            marginTop: "5vh",
            marginLeft: "0px",
            display: "flex",
            justifyContent: "center",  // гарантирует центрирование горизонтально
            height: "auto",  // убираем фиксированную высоту
            width: "100%",   // адаптируется к размеру экрана
          }
        }>
          <div
            style={{
              position: "relative",
              width: "70%",  // ширина видео по сравнению с контейнером
              paddingBottom: "39.375%",  // отношение 16:9 (9/16*100%)
              height: 0,
              overflow: "hidden",
            }}
          >
            <iframe
              width="720"
              height="405"
              src="https://rutube.ru/play/embed/3e7aa397f5b0970a27bb1823fb346b88"
              frameBorder="0"
              allow="clipboard-write; autoplay"
              webkitAllowFullScreen
              mozallowfullscreen
              allowFullScreen
              style={
                {
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }
              }
            >

            </iframe>
          </div>
        </div>
        <div className="row" style={
          {
            marginTop: "5vh",
            marginLeft: "0px",
            display: "flex",
            justifyContent: "center",  // гарантирует центрирование горизонтально
            height: "auto",  // убираем фиксированную высоту
            width: "100%",   // адаптируется к размеру экрана
          }
        }>
          <div
            style={{
              position: "relative",
              width: "70%",  // ширина видео по сравнению с контейнером
              paddingBottom: "39.375%",  // отношение 16:9 (9/16*100%)
              height: 0,
              overflow: "hidden",
            }}
          >

            <iframe
              // width="720"
              // height="405"
              src="https://rutube.ru/play/embed/c4399827ce7dbc8c1a9dabc1fe26fd2e"
              frameBorder="0"
              allow="clipboard-write; autoplay"
              webkitAllowFullScreen
              mozallowfullscreen
              allowFullScreen
              style={
                {
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }
              }
            >
            </iframe>
          </div>
        </div>
      </div>
    </div>
  );
};
